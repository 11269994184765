div.actionBar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: #1e1e1e;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 0.5px solid var(--bloomberg-orange);
    z-index: 1000;
}

div.actionBar button {
    border: none;
    border-right: 1px solid #333;
    background-color: #1e1e1e;
    color: #FF6A00;
    width: 100px;
    height: 69px;
}