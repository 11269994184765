.landingContainer {
    width: 90%;
    max-width: 800px;
    padding: 20px;
    height: 100%;
}

.landingContainer h2 {
    color: var(--bloomberg-orange);
}

/* Hero Section */
.heroSection {
    text-align: center;
    padding: 60px 20px;
    background: linear-gradient(to right, var(--bloomberg-orange), var(--bloomberg-red));
    border-radius: 10px;
    color: white;
    margin-bottom: 40px;
}

.heroSection h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.heroSubtitle {
    font-size: 1.5rem;
    margin-bottom: 40px;
    opacity: 0.9;
}

.waitlistForm {
    max-width: 500px;
    margin: 0 auto;
}

.waitlistForm form {
    display: flex;
    gap: 10px;
}

.waitlistForm input {
    flex: 1;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
}

/* Features Section */
.featuresSection {
    padding: 40px 0;
}

.featuresGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    margin-top: 40px;
}

.featureCard {
    padding: 30px;
    text-align: center;
    background: var(--bloomberg-black);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.featureCard h3 {
    color: var(--bloomberg-white);
}
.featureCard p {
    color: var(--bloomberg-orange);
}

.featureIcon {
    font-size: 2.5rem;
    color: var(--bloomberg-orange);
    margin-bottom: 20px;
}

/* Pricing Section */
.pricingSection {
    padding: 40px 0;
}

.pricingGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    margin-top: 40px;
}

.pricingCard {
    padding: 30px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

.pricingCard h3 {
    color: var(--bloomberg-orange);
}


.popularPlan {
    border: 2px solid var(--bloomberg-red);
    transform: scale(1.05);
}

.popularBadge {
    position: absolute;
    top: -12px;
    right: 20px;
    background: var(--bloomberg-red);
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 0.9rem;
}

.price {
    font-size: 2rem;
    color: var(--bloomberg-blue);
    margin: 20px 0;
}

.pricingCard ul {
    list-style: none;
    padding: 0;
}

.pricingCard li {
    margin: 10px 0;
    padding-left: 20px;
    position: relative;
}

.pricingCard li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: var(--bloomberg-green);
}

/* FAQ Section */
.faqSection {
    padding: 40px 0;
}

.faqGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.faqItem {
    background: var(--bloomberg-black);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.faqItem h3 {
    color: var(--bloomberg-orange);
}

.faqItem p {
    color: var(--bloomberg-white);
}

.successMessage {
    color: var(--bloomberg-green);
    margin-top: 10px;
}

.errorMessage {
    color: var(--bloomberg-red);
    margin-top: 10px;
}

@media (max-width: 768px) {
    .heroSection h1 {
        font-size: 2rem;
    }

    .heroSubtitle {
        font-size: 1.2rem;
    }

    .waitlistForm form {
        flex-direction: column;
    }

    .popularPlan {
        transform: none;
    }

    .faqGrid {
        grid-template-columns: 1fr;
    }
} 