/* Footer.css */

.footer {
    background-color: var(--background-color2); /* Light gray background */
    color: var(--primary-color); /* Dark text */
    text-align: center; /* Center the text */
    padding: 10px; /* Padding around the text */
    position: relative; /* Position relative for footer */
    bottom: 0; /* Stick to the bottom */
    width: 100%; /* Full width */
    height: 100px;
} 