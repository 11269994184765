.statsTableContainer {
    margin: 20px 0;
    /* border: 1px solid var(--bloomberg-gray); */
    border-collapse: collapse;
    width: 100%;
    max-height: 400px;
    overflow-x: auto;
    overflow-y: scroll;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2);
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

/* .statsTable {
    width: 100%;
    border-collapse: collapse;
} */

table.statsTable {
    table-layout: fixed;
    border-collapse: collapse;
    /* border-color: var(--bloomberg-black) !important; */
}

table.statsTable th, .statsTable td {
    
    text-align: left;
    /* border: 1px solid var(--bloomberg-gray); */
}

table.statsTable th {
    padding: 8px;
    color: var(--bloomberg-orange);
    background-color: var(--bloomberg-black);
    
}

table.statsTable td {
    padding: 8px;
    background-color: var(--bloomberg-black);
    color: var(--bloomberg-orange);
}


@media (max-width: 600px) {
    .statsTable th, .statsTable td {
        padding: 4px;
        font-size: 10px;
    }
} 