.profileContainer {
    width: 90%;
    max-width: 800px;
    padding: 20px;
    height: 100%;
    margin-bottom: 40px;
}

.profileContainer h3 {
    color: var(--bloomberg-orange);
    margin-bottom: 20px;
    font-size: 1.5rem;
}
.profileHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.profileHeader button {
    background-color: var(--bloomberg-red);
    color: white;
    border: none;
    border-radius: 5px;
    height: 30px;
    width: 150px;
    line-height: 10px;
}

.profileSection {
    margin-bottom: 30px;
    padding: 20px;
    background-color: var(--bloomberg-black);
    color: var(--bloomberg-orange);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: baseline;
}



.profileSection h3 {
    color: var(--bloomberg-orange);
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.formGroup {
    margin-bottom: 15px;
}

.formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.formGroup input {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--bloomberg-gray);
    border-radius: 4px;
}

.buttonGroup {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    flex-direction: row;
    align-items: flex-start;
}



.statusMessage {
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    background-color: var(--bloomberg-green);
    color: white;
    text-align: center;
}

/* Modal Styles */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: var(--bloomberg-orange);
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
}

.tierGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin: 20px 0;
}

.tierCard {
    padding: 20px;
    /* border: 2px solid var(--bloomberg-gray); */
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: white;
    color: var(--bloomberg-orange);
}

.tierCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.selectedTier {
    border-color: var(--bloomberg-blue);
    background-color: rgba(0, 114, 184, 0.1);
}

.price {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--bloomberg-blue);
    margin: 10px 0;
}

.tierCard ul {
    list-style: none;
    padding: 0;
}

.tierCard li {
    margin: 10px 0;
    padding-left: 20px;
    position: relative;
}

.tierCard li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: var(--bloomberg-green);
}

.modalButtons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
    flex-direction: row;
    align-items: flex-start;
}

@media (max-width: 600px) {
    .tierGrid {
        grid-template-columns: 1fr;
    }
} 