.settingsContainer {
    width: 90%;
    max-width: 800px;
    padding: 20px;
    height: 100%;
}

.settingsContainer h3 {
    color: var(--bloomberg-orange);
}

.settingsSection {
    background-color: var(--bloomberg-black);
    color: var(--bloomberg-orange);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.settingsSection h2 {
    color: var(--bloomberg-orange);
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.checkboxGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.checkboxLabel input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.inputGroup select {
    margin-left: 10px;
    padding: 5px;
    border: 1px solid var(--bloomberg-gray);
    border-radius: 4px;
}

.saveSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.saveStatus {
    padding: 10px;
    border-radius: 4px;
    text-align: center;
}

.saveStatus:not(:empty) {
    background-color: var(--bloomberg-green);
    color: white;
}

@media (max-width: 600px) {
    .checkboxGrid {
        grid-template-columns: 1fr;
    }
} 