.card {
    background-color: var(--bloomberg-black);
    color: var(--bloomberg-orange);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.cardLeft {
    flex: 1;
}

.cardRight {
    flex: 1;
}

.cardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.cardBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}