/* globals.css */
:root {
    --bloomberg-green: #00B140;
    /* Bloomberg green color */
    --bloomberg-yellow: #F6EB61;
    /* Bloomberg yellow color */
    --bloomberg-red: #D50032;
    /* Bloomberg red color */
    --bloomberg-blue: #0072B8;
    /* Bloomberg blue color */
    --bloomberg-gray: #333333;;
    /* --bloomberg-gray: #A7A8AA; */
    /* Bloomberg gray color */
    --bloomberg-black: #1E1E1E;
    /* Bloomberg black color */
    --bloomberg-white: #FFFFFF;
    /* Bloomberg white color */
    --bloomberg-orange: #FF6A13;
    /* Bloomberg orange color */

    --background-color1: #EDF2F4;
    --background-color2: #1E1E1E;
    --background-color3: #8D818C;
    --primary-color: #FF6A13;
    --secondary-color: #5C95FF;
}
/* Generic button styles */
.btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.viewContainer {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-start;
    padding-top: 70px;
    padding-bottom: 92px;
    overflow-y: scroll;
    min-height: 760px;
    max-height: 760px;
    background-color: var(--bloomberg-gray);
    position: absolute;
    left: 0;
    right: 0;
}


/* Custom container styles for full web page length */
.fullPageContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

/* Additional styling for a centered content area */
.centeredContent {
    width: 100%;
    max-width: 800px;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
}

.pageContainer {
    position: relative;
    margin-top: 50px;
    height: 100vh;
    overflow-y: scroll;
}

/* Button styles */
.primaryBtn {
    background-color: #4B4B4B;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    font-size: 16px;
    color: #FF6A00;
    width: 200px;
    margin-bottom: 20px;
}
.secondaryBtn {
    background-color: var(--bloomberg-red);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    /* margin: 0 10px; */
    cursor: pointer;
    font-size: 16px;
    color: white;
    /* margin-bottom: 20px; */
}

.smallBtn {
    height: 30px;
    width: 60px;
    border: none;
    border-radius: 5px;
    color: var(--blue);
    background-color: var(--bloomberg-black);
    color: var(--bloomberg-orange);
    cursor: pointer;
}
.simpleForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 40px;
    width: 300px;
}

.simpleFormRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    line-height: 40px;
    align-items: center;
}

.slight3dEffect {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2);
}


.card {

}