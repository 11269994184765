.chatContainer {
    display: flex;
    flex-direction: column;
    height: 400px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
}

.chatHistory {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f9f9f9;
}

.userMessage {
    text-align: right;
    margin: 5px 0;
    padding: 5px 10px;
    background-color: #d1e7dd;
    border-radius: 10px;
    display: inline-block;
}

.aiMessage {
    text-align: left;
    margin: 5px 0;
    padding: 5px 10px;
    background-color: #f8d7da;
    border-radius: 10px;
    display: inline-block;
}

.chatInputContainer {
    display: flex;
    padding: 10px;
    background-color: #fff;
}

.chatInput {
    flex: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.sendButton {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.sendButton:hover {
    background-color: #0056b3;
} 