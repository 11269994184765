/* Banner.css */

.banner {
    background-color: #1e1e1e;
    color: #FF6A00;
    text-align: center;
    height: 70px;
    line-height: 0px;
    line-height: 35px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid var(--bloomberg-orange);
    z-index: 1000;
} 