/* HomePage.css */

/* ÷÷÷ */
.parallax {
    background-image: url('https://example.com/your-image.jpg'); /* Replace with your image URL */
    height: 100%;
    background-attachment: fixed; /* Parallax effect */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white; /* Text color */
}

.homeBtnContainer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    
}

.primaryBtn {
    background-color: #4B4B4B;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    font-size: 16px;
    color: #FF6A00;
    width: 200px;
    margin-bottom: 20px;
}

.primaryBtn:hover {
    background-color: rgba(200, 200, 200, 1); /* Lighter version of #4B4B4B on hover */
} 


.mainMenuDriver {
    position: fixed;
    bottom: 100px;
    right: 40px;    
    height: 50px;
    width: 50px;
}

.mainMenuBtn {
    border: none;
    color: white;
    font-size: 24px;
    height: 50px;
    width: 50px;
    border-radius: 30px;
    color: white;
    background-color: #4B4B4B;
    z-index: 1000;
}
.mainMenuList {
    position: absolute; /* Change to absolute positioning */
    background-color: white;
    color: black;
    right: 60px; /* Position it to the left of the .mainMenuBtn */
    bottom: 0; /* Align it with the bottom of the .mainMenuBtn */
    height: 200px;
    width: 200px;
    display: flex;
    flex-direction: row;
    
}
.menuDriver {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100[x];
    width: 100%;
}
.mainMenuList ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.mainMenuList li {
    padding: 10px;
}

.mainMenuList li a {
    text-decoration: none;
}
.betFeedContainer {
    display: flex;
    flex-grow: 1;
    /* Take up the remaining space */
    justify-content: center;
    /* Optional, to center content horizontally */
    align-items: flex-start;
    /* Align content at the top */
    padding-top: 70px;
    /* Offset for banner */
    padding-bottom: 92px;
    /* Offset for button menu */
    overflow-y: scroll;
    max-height: 760px;
    background-color: #f9f9f9;
}
div.betFeed {
    width: 90%;
    /* Width of the feed */
    max-width: 800px;
    /* Max width for the feed */
    
    padding: 20px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    height: 100%;
}






div.betDataCardModalContainer {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: white;
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 1001;
    top: 70px;
    /* padding-left: 20px; */
    /* padding-right: 20px; */
    /* padding-top: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.betDataCardContainer {
    height: 100%;
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
}

div.betDataCardHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
}

div.betDataCardPlayerInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

div.betDataCardHeaderLeft {
    width: 50%;
}

div.betDataCardHeaderRight {
    width: 50%;
}

div.betDataCardHeaderClose {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
div.betDataCardBody {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
}

div.betDataSubSection {
    display: flex;
    flex-direction: column;
    width: 100%;
}

div.betDataCardStatSelection {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}



div.betDataCardFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.statsChartSelection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

div.statsChartSelection select {
    height: 30px;
    width: 100px;
}

/* inDepthBetCard */

.inDepthBetCardBtn {
    height: 30px;
    width: 60px;
    border: none;
    border-radius: 5px;
    background-color: var(--bloomberg-black);
    color: var(--bloomberg-orange);
    cursor: pointer;
}