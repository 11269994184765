div.betDataCardModalContainer {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: var(--bloomberg-gray);
    /* background-color: white; */
    z-index: 1001;
    top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.betDataCardContainer {
    height: 100%;
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: column;
}

div.betDataCardHeader {
    display: flex;
    flex-direction: column;
    width: 100%;
}

div.betDataCardPlayerInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    color: var(--bloomberg-orange);
    background-color: var(--bloomberg-black);
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
}

div.betDataCardHeaderLeft {
    width: 50%;
}

div.betDataCardHeaderRight {
    width: 50%;
}

div.betDataCardHeaderClose {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
div.betDataCardBody {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
}

div.betDataSubSection {
    display: flex;
    flex-direction: column;
    width: 100%;
}

div.betDataCardStatSelection {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

div.betDataCardFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.statsChartSelection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

div.statsChartSelection select {
    height: 30px;
    width: 80px;
    color: var(--bloomberg-orange);
    background-color: var(--bloomberg-black);
    border: none;
    border-radius: 5px;
}

.inDepthBetCardBtn {
    height: 30px;
    width: 60px;
    border: none;
    border-radius: 5px;
    background-color: var(--bloomberg-black);
    color: var(--bloomberg-orange);
    cursor: pointer;
} 

.inDepthBetCardScoutingReportTextarea {
    height: 400px;
    width: 100%;
    background-color: var(--bloomberg-black);
    color: var(--bloomberg-orange);
}