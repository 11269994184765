.betFeedContainer {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-start;
    /* padding-top: 70px; */
    padding-bottom: 92px;
    overflow-y: scroll;
    max-height: 760px;
    background-color: var(--bloomberg-gray);
}

.betFeed {
    width: 90%;
    max-width: 800px;
    padding: 20px;
    height: 100%;
} 