.searchContainer {
    width: 90%;
    max-width: 800px;
    padding: 20px;
    height: 100%;
}

.searchContainer h3 {
    color: var(--bloomberg-orange);
}

.searchBarContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.searchInput {
    width: 100%;
    max-width: 600px;
    padding: 12px 20px;
    border: 2px solid var(--bloomberg-gray);
    border-radius: 25px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
}

.searchInput:focus {
    border-color: var(--bloomberg-orange);
}

.searchResults {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
} 