/* src/components/sliderView.module.css */
.sliderContainer {
    display: flex;
    width: 100%;
    overflow: hidden;
    height: 500px;
    flex-direction: column;
}

.sliderContent {
    flex: 1;
    display: flex;
    /* justify-content: center;
    align-items: center; */
    transition: transform 0.3s ease-in-out;
}
.sliderBtn {
    height: 30px;
    width: 60px;
    border: none;
    border-radius: 5px;
    color: var(--blue);
    background-color: var(--bloomberg-black);
    color: var(--bloomberg-orange);
    cursor: pointer;
}
.navButton {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.navButton:hover {
    background-color: #0056b3;
}

.sliderHeader {
    display: flex;
    height: 100px;
    flex-direction: row;
    justify-content: space-between;
}
