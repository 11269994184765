/* register.css */

.registerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.registerCard {
    padding: 40px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    background-color: var(--bloomberg-black);
}

.registerCard h2 {
    color: var(--bloomberg-orange);
    text-align: center;
    margin-bottom: 30px;
}

.registerForm {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.formGroup label {
    color: var(--bloomberg-black);
    font-weight: 500;
}

.input {
    padding: 12px;
    border: 1px solid var(--bloomberg-gray);
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.input:focus {
    outline: none;
    border-color: var(--bloomberg-blue);
}

.loginPrompt {
    margin-top: 20px;
    text-align: center;
}

.loginPrompt p {
    margin-bottom: 10px;
    color: white;
}