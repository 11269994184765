.noImagePlaceholder {
    width: 120px;
    height: 180px;
    background-color: gray;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
}